import $ from 'jquery';
import React, { Fragment } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import '@progress/kendo-ui';

const Util = {
    isNullOrUndefined: (val) => {
        return val === undefined || val === null;
    },
    /* Returns true if the provided string is null, empty, or only whitespace */
    isNullOrWhitespace: function (str) {
        if (str === undefined || str === null) return true;
        if (str === 0) return false;
        if (typeof str === 'object') return false;
        return !str || (typeof str === 'string' && str.match(/^ *$/)) !== null;
    },
    _padTo2: (num) => {
        return ("00" + num).slice(-2);
    },
    strContains: (str, containsVal, caseInsensitive)=> {
        if (caseInsensitive !== true) caseInsensitive = false;
        else str = str.toLowerCase();
        if (containsVal instanceof Array) {
            for (var i = 0; i < containsVal.length; i++) {
                var valToCheck = caseInsensitive ? containsVal[i].toLowerCase() : containsVal[i];
                if (str.indexOf(valToCheck) !== -1) return true;
            }
            return false;
        }
        else return Util.strContains(str, [containsVal], caseInsensitive);
    },
    endsWith: (str, endsWithVal, caseInsensitive)=> {
        if (caseInsensitive !== true) caseInsensitive = false;
        else str = str.toLowerCase();
        if (endsWithVal instanceof Array) {
            for (var i = 0; i < endsWithVal.length; i++) {
                var valToCheck = caseInsensitive ? endsWithVal[i].toLowerCase() : endsWithVal[i];
                if (str.indexOf(valToCheck, str.length - valToCheck.length) !== -1) return true;
            }
            return false;
        }
        else return Util.endsWith(str, [endsWithVal], caseInsensitive);
    },
    showMessage: (divtitle, boxtitle, text) => {
        return class extends React.Component {

            state = {
                visible: true
            }

            toggleDialog = (e) => {
                this.setState({
                    visible: !this.state.visible
                });
            }

            render() {
                return (
                    <Fragment>
                        {
                            this.state.visible && <Dialog title={divtitle} onClose={this.toggleDialog}>
                                <p style={{ margin: "25px", textAlign: "center" }}>Are you sure you want to continue?</p>
                                <DialogActionsBar>
                                    <button className="k-button" onClick={this.toggleDialog}>No</button>
                                    <button className="k-button" onClick={this.toggleDialog}>Yes</button>
                                </DialogActionsBar>
                            </Dialog>}
                    </Fragment>
                );
            }
        }
    },
    showMessage1: (divtitle, boxtitle, text) => {

        if (!divtitle || !text) {
            console.log("Window must be given a html element and valid text.");
            return;
        }
        var mWindow = $("#" + divtitle);
        if (!mWindow.data("kendoWindow")) {
            mWindow.kendoWindow({
                actions: ["Close"],
                animation: {
                    open: {
                        effects: "fade:in",
                        duration: 500
                    },
                    close: {
                        effects: "fade:out",
                        duration: 500
                    }
                },
                draggable: true,
                modal: true,
                resizable: false,
                title: boxtitle ? boxtitle : "Error",
                width: 400,
                visible: true
            });
            mWindow.data("kendoWindow").content("<p>" + text + "</p>").center().open();
        }
        else {
            mWindow.data("kendoWindow").title(boxtitle ? boxtitle : "Error").content("<p>" + text + "</p>").open();
        }
        //Add OK button to close the message window
        var $closeButton = $('<input type="button" value="OK" class="k-button" />');
        $closeButton.click(function () {
            mWindow.data('kendoWindow').close();
        });
        $('<p style="text-align:center;" />').append($closeButton).insertAfter(mWindow.children('p').last());
    },
    charLimit: (str, limit) => {
        if ($.trim(str).length <= limit) return str;
        limit -= 3;
        return $.trim(str.substring(0, limit)) + '...';
    },
    roundToPrecision: function (val, precision) {
        if (Util.isNullOrUndefined(val)) return null;
        var multiplier = Math.pow(10, precision);
        return Math.round(val * multiplier) / multiplier;
    },
    flatDataToHierarchical: (data, idFieldName, parentIdFieldName, parentIdentifier) => {
        var hierarchicalDataArr = [];
        for (var i = 0; i < data.length; i++) {
            if (!data[i][parentIdFieldName]) {
                var newItem = $.extend(true, {}, data[i]);
                Util.populateHierarchicalChildren(data, newItem, idFieldName, parentIdFieldName, parentIdentifier);
                hierarchicalDataArr.push(newItem);
            }
        }
        return hierarchicalDataArr;
    },
    populateHierarchicalChildren: (data, parentItem, idFieldName, parentIdFieldName, parentIdentifier) => {
        var subItems = [];
        for (var i = 0; i < data.length; i++) {
            if (data[i][parentIdFieldName] == parentItem[idFieldName] && (!parentIdentifier || parentItem[parentIdentifier])) {
                var newItem = $.extend(true, {}, data[i]);
                Util.populateHierarchicalChildren(data, newItem, idFieldName, parentIdFieldName, parentIdentifier);
                subItems.push(newItem);
            }
        }
        if (subItems) parentItem.items = subItems;
    },
    flattenHierarchicalData: (data, childrenArrayName, flatDataArr) => {
        if (!data || data.length === 0) return flatDataArr;
        if (!childrenArrayName) childrenArrayName = 'items';
        if (!flatDataArr) flatDataArr = [];
        for (var i = 0; i < data.length; i++) {
            var newItem = $.extend(true, {}, data[i]);
            var childArr = data[i][childrenArrayName];
            delete newItem[childrenArrayName];
            flatDataArr.push(newItem);
            Util.flattenHierarchicalData(childArr, childrenArrayName, flatDataArr);
        }
        return flatDataArr;
    },
    getQueryStringParameterByName: (name, queryString) => {
        if (Util.isNullOrUndefined(queryString)) queryString = decodeURI(window.location.search);
        if (!queryString) return '';
        if (queryString.substr(0, 1) != '?') queryString = '?' + queryString;
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var pattern = '[\\?&]' + name + '(\\[([^\\]]+)\\])+=[^&#]*';
        var o_reg = new RegExp(pattern, 'g');
        var matches = queryString.match(o_reg);

        if (matches && matches.length > 0) {
            var firstMatchArr = matches[0].split('=');
            var splitFirstKey = firstMatchArr[0].split('[');
            splitFirstKey.shift();
            var depth = splitFirstKey.length;
            var indexIsObject = [];
            for (var i = 0; i < splitFirstKey.length; i++) {
                if (isNaN(splitFirstKey[i].substring(0, splitFirstKey[i].length - 1))) indexIsObject.push(true); //Index is for an object if key is not a number
                else indexIsObject.push(false); //Otherwise index is for an array
            }
            var parent = indexIsObject[0] ? {} : [];
            for (var j = 0; j < matches.length; j++) {
                var kvp = matches[j].split('=');
                var value = kvp[1];
                var splitKey = kvp[0].split('[');
                splitKey.shift();
                var objToCheck = parent;
                for (var k = 0; k < splitKey.length; k++) {
                    var subKey = splitKey[k].substring(0, splitKey[k].length - 1);
                    if (k == splitKey.length - 1) {
                        objToCheck[subKey] = value;
                    }
                    else {
                        if (objToCheck[subKey] === undefined) {
                            if (indexIsObject[k + 1]) objToCheck[subKey] = {};
                            else objToCheck[subKey] = [];
                        }
                    }
                    objToCheck = objToCheck[subKey];
                }
            }
            return parent;
        }

        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(queryString);
        return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },
    getDistanceBetweenLatLngPoints: function (lat1, lng1, lat2, lng2) {
        var radiusOfEarth = 6371100;
        var deltaLat = Util.degreeToRadian(lat2 - lat1);
        var deltaLng = Util.degreeToRadian(lng2 - lng1);
        var a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) + Math.cos(Util.degreeToRadian(lat1)) * Math.cos(Util.degreeToRadian(lat2)) * Math.sin(deltaLng / 2) * Math.sin(deltaLng / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return c * radiusOfEarth;
    },

    /* Converts angle from degrees to radian
     */
    degreeToRadian: function (angle) {
        return Math.PI * angle / 180.0;
    },

    /* Converts angle from radian to degrees
     */
    radianToDegree: function (angle) {
        return angle * (180.0 / Math.PI);
    },
    findDistanceToLineSegment: function (x1, y1, x2, y2, pointX, pointY, isLatLng) {
        var diffX = x2 - x1;
        var diffY = y2 - y1;
        if ((diffX == 0) && (diffY == 0)) {
            if (isLatLng) return Util.getDistanceBetweenLatLngPoints(pointX, pointY, x1, y1);
            diffX = pointX - x1;
            diffY = pointY - y1;
            return Math.sqrt(diffX * diffX + diffY * diffY);
        }

        var t = ((pointX - x1) * diffX + (pointY - y1) * diffY) / (diffX * diffX + diffY * diffY);

        if (t < 0) {
            //point is nearest to the first point i.e x1 and y1
            if (isLatLng) return Util.getDistanceBetweenLatLngPoints(pointX, pointY, x1, y1);
            diffX = pointX - x1;
            diffY = pointY - y1;
        }
        else if (t > 1) {
            //point is nearest to the end point i.e x2 and y2
            if (isLatLng) return Util.getDistanceBetweenLatLngPoints(pointX, pointY, x2, y2);
            diffX = pointX - x2;
            diffY = pointY - y2;
        }
        else {
            //if perpendicular line intersect the line segment.
            if (isLatLng) return Util.getDistanceBetweenLatLngPoints(pointX, pointY, (x1 + t * diffX), (y1 + t * diffY));
            diffX = pointX - (x1 + t * diffX);
            diffY = pointY - (y1 + t * diffY);
        }

        //returning shortest distance
        return Math.sqrt(diffX * diffX + diffY * diffY);
    },
    findDistanceBetweenLineSegments: function (l1x1, l1y1, l1x2, l1y2, l2x1, l2y1, l2x2, l2y2, isLatLng) {
        if (Util.checkIfLinesIntersect(l1x1, l1y1, l1x2, l1y2, l2x1, l2y1, l2x2, l2y2)) return 0;
        return Math.min(
            Util.findDistanceToLineSegment(l1x1, l1y1, l1x2, l1y2, l2x1, l2y1, isLatLng),
            Util.findDistanceToLineSegment(l1x1, l1y1, l1x2, l1y2, l2x2, l2y2, isLatLng),
            Util.findDistanceToLineSegment(l2x1, l2y1, l2x2, l2y2, l1x1, l1y1, isLatLng),
            Util.findDistanceToLineSegment(l2x1, l2y1, l2x2, l2y2, l1x2, l1y2, isLatLng)
        );
    },
    checkIfLinesIntersect: function (x1, y1, x2, y2, x3, y3, x4, y4) {
        var x = ((x1 * y2 - y1 * x2) * (x3 - x4) - (x1 - x2) * (x3 * y4 - y3 * x4)) / ((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4));
        var y = ((x1 * y2 - y1 * x2) * (y3 - y4) - (y1 - y2) * (x3 * y4 - y3 * x4)) / ((x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4));
        if (isNaN(x) || isNaN(y)) {
            return false;
        } else {
            if (x1 >= x2) {
                if (!(x2 <= x && x <= x1)) { return false; }
            } else {
                if (!(x1 <= x && x <= x2)) { return false; }
            }
            if (y1 >= y2) {
                if (!(y2 <= y && y <= y1)) { return false; }
            } else {
                if (!(y1 <= y && y <= y2)) { return false; }
            }
            if (x3 >= x4) {
                if (!(x4 <= x && x <= x3)) { return false; }
            } else {
                if (!(x3 <= x && x <= x4)) { return false; }
            }
            if (y3 >= y4) {
                if (!(y4 <= y && y <= y3)) { return false; }
            } else {
                if (!(y3 <= y && y <= y4)) { return false; }
            }
        }
        return true;
    },
    trim: function (str, char) {
        if (!char) return str.replace(/(^\s+)|(\s+$)/g, "");
        var escapedChar = Util.escapeStringForRegExp(char);
        var regexStr = '(^' + escapedChar + '+)|(' + escapedChar + '+$)';
        return str.replace(new RegExp(regexStr, 'g'), "");
    },
    /* Escape string for use in a regular expression */
    escapeStringForRegExp: function (str) {
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    },
    /* Checks if a string starts with a value. An array of strings can be provided as the startsWithVal parameter
     * in which case true will be returned if the string starts with any of the substrings in the array
     */
    startsWith: function (str, startsWithVal, caseInsensitive) {
        if (caseInsensitive !== true) caseInsensitive = false;
        else str = str.toLowerCase();
        if (startsWithVal instanceof Array) {
            for (var i = 0; i < startsWithVal.length; i++) {
                var valToCheck = caseInsensitive ? startsWithVal[i].toLowerCase() : startsWithVal[i];
                if (str.lastIndexOf(valToCheck, 0) === 0) return true;
            }
            return false;
        }
        else return this.startsWith(str, [startsWithVal], caseInsensitive);
    },
    /* Forces all grids on page to appear loading
     */
    loadingOn: function () {
        this.currentlyLoading = true;
        var self = this;        
        $('body').addClass('gridHelper-loading');
        //do not show progress forever:
        if (window._loadingOnCancelTimer !== undefined) {
            window.clearTimeout(window._loadingOnCancelTimer);
            delete window._loadingOnCancelTimer;
        }
        window._loadingOnCancelTimer = window.setTimeout(function () {
            delete window._loadingOnCancelTimer;
            if (self.currentlyLoading) {
                Util.loadingOff();
                console.error('loadingOff was not called before timeout');
            }
        }, 20000);
    },

    /* Removes loading state from all grids on page
     */
    loadingOff: function () {
        this.currentlyLoading = false;        
        $('body').removeClass('gridHelper-loading');
    },
    setCookie: function (c_name, value, exdays) {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = escape(value) + ((exdays === null) ? "" : "; expires=" + exdate.toUTCString());
        document.cookie = c_name + "=" + c_value;
    },

    getCookie: function (c_name) {
        var i, x, y, ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {
            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");
            if (x === c_name) {
                return unescape(y);
            }
        }
    },
    copyTextToClipboard: function (text) {
        // Create the textarea input to hold our text.
        const element = document.createElement('textarea');
        element.value = text;
        // Add it to the document so that it can be focused.
        document.body.appendChild(element);
        // Focus on the element so that it can be copied.
        element.focus();
        element.setSelectionRange(0, element.value.length);
        // Execute the copy command.
        document.execCommand('copy');
        // Remove the element to keep the document clear.
        document.body.removeChild(element);
    },
    lastDummyIdUsed: 0,
    getNewDummyId: function () {
        Util.lastDummyIdUsed--;
        return Util.lastDummyIdUsed;
    },
    /* Generates the new name of an object copied from another
     */
    getCopyName: function (itemToCopy, items, nameField, otherFieldsToMatch) {
        if (!otherFieldsToMatch || !(otherFieldsToMatch instanceof Array)) otherFieldsToMatch = [];
        //Create regex used for counting how many copies of this row there already are:
        var name = itemToCopy[nameField];
        var cleanName = name.replace(/(\s+\(copy(\s[0-9]+)?\))?$/i, '');
        var namePattern = new RegExp('^' + Util.escapeStringForRegExp(cleanName) + '(\\s\\(copy(\\s[0-9]+)?\\))?$');

        var copyCount = 0;
        for (var i = 0; i < items.length; i++) {
            if (!items[i][nameField]) continue;
            if (namePattern.test(items[i][nameField])) {
                var otherFieldsMatch = true;
                for (var j = 0; j < otherFieldsToMatch.length; j++) {
                    var fieldToMatch = otherFieldsToMatch[j];
                    if (items[i][fieldToMatch] != itemToCopy[fieldToMatch]) {
                        otherFieldsMatch = false;
                        break;
                    }
                }
                if (otherFieldsMatch) copyCount++;
            }
        }

        if (copyCount == 0) return cleanName;
        else if (copyCount == 1) return cleanName + ' (copy)';
        else return cleanName + ' (copy ' + copyCount + ')';
    }
};

//GUID generation function from https://gist.github.com/jed/982883
export function getGuid(a) { return a ? (a ^ ((Math.random() * 16) >> (a / 4))).toString(16) : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, getGuid) }

export default Util;