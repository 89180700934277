import React, { Component} from 'react';
import axios from 'axios';
import { Provider } from './PasswordContext';
import ChangePasswordPresentation from './ChangePasswordPresentation';
import './ChangePassword.scss';

export default class ChangePassword extends Component {
    state = {
        passwordPolicy: null,
        formModel: {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        formError: {
            oldPasswordError: false,
            newPasswordError: false,
            confirmPasswordError: false
        },
        errorMessage: null
    }

    componentDidMount() {
        this.getPasswordPolicy();
    }

    getPasswordPolicy = () => {
        axios.get('/api/PasswordPolicy').then((res) => {
            let policy = res.data;
            this.setState({ passwordPolicy: policy });
        }).catch((err) => console.log(err));
    }

    handleSave = e => {
        e.preventDefault();
        let regex = null;
        let passwordPolicy = this.state.passwordPolicy;
        let formModel = this.state.formModel;

        if (passwordPolicy.regex) regex = new RegExp(passwordPolicy.regex);

        this.setState({ formError: { ...this.state.formError, oldPasswordError: false, confirmPasswordError: false, newPasswordError: false } });
        let invalidPasswordError = 'Password must be ' + passwordPolicy.description;
        if (!formModel.oldPassword) {
            this.setState({ formError: { ...this.state.formError, oldPasswordError: true }, errorMessage: "Please enter old password"});
            return;
        }
        else if ((passwordPolicy.minLength && formModel.newPassword.length < passwordPolicy.minLength) || (passwordPolicy.maxLength && formModel.newPassword.length > passwordPolicy.maxLength) || (regex && !regex.test(formModel.newPassword))) {            
            this.setState({ formError: { ...this.state.formError, newPasswordError: true }, errorMessage: invalidPasswordError});
            return;
        }
        else if (formModel.newPassword !== formModel.confirmPassword) {                       
            this.setState({ formError: { ...this.state.formError, onfirmPasswordError: true, newPasswordError: true }, errorMessage: 'Passwords do not match'});
            return;
        }
        else if (formModel.newPassword === formModel.oldPassword) {            
            this.setState({ formError: { ...this.state.formError, newPasswordError: true }, errorMessage: 'New password must be different from old password'});
            
            return;
        }

        axios.post('/api/ChangePassword', { oldPassword: formModel.oldPassword, newPassword: formModel.newPassword })
            .then(response => {                
                if (sessionStorage.getItem('changePassword')) {
                    sessionStorage.clear();
                    window.location.reload();
                }
                else {
                    this.props.onClose();
                }
            }).catch(err => {
                console.log(err);
                this.setState({ errorMessage: err.ErrorMessage});
            })
    }

    render() {
        return (
            <Provider value={{
                showModel: this.props.showModal,
                formModel: this.state.formModel,
                formError: this.state.formError,                
                passwordPolicy: this.state.passwordPolicy,
                errorMessage: this.state.errorMessage,
                handleSave: this.handleSave,
                handleInput: e => {
                    this.setState({ formModel: { ...this.state.formModel, [e.target.name]: e.target.value }, errorMessage: null });
                }
            }}>
                <div id="changePassword">
                    <ChangePasswordPresentation />
                </div>
            </Provider>
            );
    }
}
