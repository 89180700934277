import axios from 'axios';
import React, { Component } from 'react';
import Util from '../../Utils/Util';
import HomepageMenu from './HomepageMenu/HomepageMenu';

export default class NotificationMenu extends Component {

    state = {       
        messageCount: null,
        notificationCount: null,
        intervalObj: null
    }


    componentDidMount() {
       let _intervalObj =  setInterval(() => {
            axios('/api/notification').then(response => {
                let data = response.data;
                this.setState({ notificationCount: data.notificationCount, messageCount: data.messageCount });
            }).catch(err => console.log('Error loading notification'));
       }, 10000);

        this.setState({ intervalObj: _intervalObj });
    }

    componentWillUnmount() {
        if (this.state.intervalObj) {
            clearInterval(this.state.intervalObj);
            this.setState({ intervalObj: null });
        }
    }

    //This logout function is used to test login process
    onLogOut = () => {
        localStorage.clear();
        window.location = "/";
    }

    render() {
        return (
            <ul className="sysbtns">
                {this.props.subModules.indexOf('AlertBox') !== -1 &&
                    <li className="sysbtn alerts">
                        {!Util.isNullOrUndefined(this.state.notificationCount) && this.state.notificationCount > 0 && <span id="alertcount" className="withalert">{this.state.notificationCount}</span>}
                        <ul className="submenu">
                            <li onClick={this.props.onOpenInbox}>Inbox</li>
                            <li onClick={this.props.onOpenMyAlerts}>My Alerts</li>
                        </ul>
                    </li>
                }
                {this.props.subModules.indexOf('SendAlert') !== -1 &&
                    <li className="sysbtn outbox" onClick={this.props.onOpenOutbox} />
                }

                {this.props.subModules.indexOf('SendMessage') !== -1 && <li className="sysbtn messages">
                    {!Util.isNullOrUndefined(this.state.messageCount) && this.state.messageCount > 0 && (<span id="messagecount" className='withalert'>{this.state.messageCount}</span>)}
                    <ul className="submenu">
                        <li id="mni_inbox" onClick={this.props.onOpenMessageInbox}>Inbox</li>
                        <li id="mni_sendmessage" onClick={this.props.onOpenSendMessage}>Send Message</li>
                    </ul>
                </li>}
                {/* this.props.subModules.indexOf('SendMessage') !== -1 && <li className="sysbtn voip" onClick={this.props.onOpenVoip} /> */}
                <li className="sysbtn settings">
                    <ul className="submenu setting-modify">
                        <li id="changepassword" onClick={this.props.onChangePassword}>Change Password</li>
                        <li id="changehomepage" className="has-side-menu">
                            Change Homepage
                                <HomepageMenu menuTree={this.props.menuTree} />
                        </li>
                    </ul>
                </li>
                <li className="sysbtn logout" onClick={this.onLogOut} />
            </ul>
            )
    }
}
