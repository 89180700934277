import './MapComponent.scss'
import 'leaflet/dist/leaflet.css';
import '../../../../node_modules/leaflet.markercluster/dist/MarkerCluster.css';
import '../../../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css';



import React, { Component, useState, useContext, useEffect, useRef } from 'react';
import L from 'leaflet';
import { TileLayer } from 'react-leaflet';
import { SystemSettingContext } from '../../../SystemSettingContext';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../../../reducers/Map';
import 'leaflet-bing-layer';
import CryptoJS from 'crypto';
import MapPresentation from './MapComponentPresentation';

const openStreetAttribution = '&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors';
const azureMapAttribution = `&copy; ${new Date().getFullYear()} TomTom, Microsoft`;

export const MapComponentUnconnected = React.forwardRef((props, mapRef) => {
    if (!mapRef) mapRef = useRef();
    const[showArial, setShowArial] = useState(false);
    const [bingLayer, setBingLayer] = useState(null);
    const [mapAttribution, SetMapAttribution] = useState(openStreetAttribution)
    const setBingLayerStateWithCallback = (newState, callback) => {
        setBingLayer(newState);
        callback();
    }
    const { dispatch } = props;
    const SystemSettings = useContext(SystemSettingContext);

    const centerPosition = props.centerPosition || [SystemSettings ? SystemSettings.mapCenterLatitude : 0, SystemSettings ? SystemSettings.mapCenterLongitude : 0];
    const zoom = SystemSettings ? SystemSettings.mapCenterZoom : 12;
    const showArialMapView = SystemSettings ? SystemSettings.showArialMapView : false;

    let mapTileUrl = "https://map.mapstrat.com/osm/{z}/{x}/{y}.png";

    const mapTiles = <TileLayer maxZoom={20}
        attribution={mapAttribution}
        url={SystemSettings && SystemSettings.useMapTiles && SystemSettings.mapTileUrl && SystemSettings.mapTileUrl.length > 0 ? SystemSettings.mapTileUrl : mapTileUrl}
    />;

    const toggleAerial = () => {
        //Show bing maps aerial tiles if aerial is turned on        
        if (!showArial) {
            if (!bingLayer) {
                let _layer = L.tileLayer('https://atlas.microsoft.com/map/tile?subscription-key={subscriptionKey}&api-version=2.0&tilesetId={tilesetId}&zoom={z}&x={x}&y={y}&tileSize=256&language={language}&view={view}', {
                    attribution: azureMapAttribution,
                    subscriptionKey: SystemSettings.azureMapSubscriptionKey ||'2ZouVXBv0hqRGn7dGgw609hV1Vkjh7bmeRXGvtFhTwM',                   
                    tilesetId: 'microsoft.imagery',
                    language: 'en-US',
                    maxZoom:19,
                    view: 'Auto'
                });                               

                SetMapAttribution(azureMapAttribution);
                //let _layer = L.tileLayer.bing({
                //    bingMapsKey: SystemSettings.bingMapKey,
                //    imagerySet: 'AerialWithLabels'
                //});
                //bing layer update is used with callback because this is async and it will throw exception if layer addTo
                //method is executed before updating the bing layer update  
                setBingLayerStateWithCallback(_layer, () => _layer.addTo(mapRef.current.leafletElement));                
            }
            if (bingLayer) {                              
                bingLayer.addTo(mapRef.current.leafletElement);
                SetMapAttribution(azureMapAttribution);
            };

            mapRef.current.leafletElement.options.maxZoom = 19;

            let mapCurrentZoom = mapRef.current.leafletElement.getZoom();
            if (mapCurrentZoom > 19)
                mapRef.current.leafletElement.setZoom(19)
        }
        else {
            mapRef.current.leafletElement.options.maxZoom = 20;
            SetMapAttribution(openStreetAttribution);
            mapRef.current.leafletElement.removeLayer(bingLayer);        
        }
        setShowArial(!showArial);
    }
    const getRandomLatLng = (map) => {
        var bounds = map.getBounds(),
            southWest = bounds.getSouthWest(),
            northEast = bounds.getNorthEast(),
            lngSpan = northEast.lng - southWest.lng,
            latSpan = northEast.lat - southWest.lat;

        return L.latLng(
            southWest.lat + latSpan * Math.random(),
            southWest.lng + lngSpan * Math.random());
    }

    const decryptMapKey = (k) => {
        var KEY = CryptoJS.enc.Utf8.parse('1234567890123456');
        var decryptedBytes = CryptoJS.AES.decrypt(k, KEY, { iv: KEY });
        var decryptedDoc = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return decryptedDoc;
    }

    useEffect(() => { if (props.boundPosition && props.boundPosition.length > 0) mapRef.current.leafletElement.fitBounds(props.boundPosition); }, [props.boundPosition]);

    useEffect(() => {
        if (props.mapZoomPosition) {
            mapRef.current.leafletElement.flyTo(props.mapZoomPosition, 17, { animate: false });
        }
    }, [props.mapZoomPosition]);

    useEffect(() => {
        if (!dispatch || !mapRef) return;
        const mapActions = bindActionCreators(actions, dispatch);
        mapActions.setMapRef(mapRef);
        return () => {
            if (mapActions) mapActions.clearMap();
        }
    }, [dispatch, mapRef]);

    const contextMenu = e => {
        console.log('context menu from the un connected map component');
    }

    return (
        <MapPresentation props={props} centerPosition={centerPosition} oncontextmenu={contextMenu}
            showArial={showArial} zoom={zoom} ref={mapRef} mapTiles={mapTiles}
            toggleAerial={toggleAerial} showArialMapView={showArialMapView} />
    );
});

export default connect(state => {
    return {
        markerGroups: state.mapReducer ? state.mapReducer.MarkerGroups : null,
        vehicleLogs: state.mapReducer ? state.mapReducer.VehicleAuditLogs : null,
        vehicleMarker: state.mapReducer ? state.mapReducer.VehicleMarker : null,
        historyRouteLines: state.mapReducer ? state.mapReducer.HistoryRouteLines : null,
        stopMarkers: state.mapReducer ? state.mapReducer.StopMarkers : null,
        routeLines: state.mapReducer ? state.mapReducer.RouteLines : null,
        clusterMarkers: state.mapReducer ? state.mapReducer.ClusterMarkers : null,
        circleMarkers: state.mapReducer ? state.mapReducer.CircleMarkers : null,
        mapZoomPosition: state.mapReducer ? state.mapReducer.MapZoomPosition : null,
        boundPosition: state.mapReducer ? state.mapReducer.BoundPosition : null,
        polygons: state.mapReducer ? state.mapReducer.Polygons : null,
        drawableRoutes: state.mapReducer ? state.mapReducer.DrawableRoutes : null,
        isConnected: true
    }
})(MapComponentUnconnected);