import axios from 'axios';

let alertDiv;
function updateOnlineStatus(event) {
    if (!navigator.onLine && !alertDiv) {
        alertDiv = document.createElement('div');
        alertDiv.id = 'alertDiv';
        alertDiv.style = "text-align:center; position:fixed; display:inline-block; width:100%;min-height:60px;box-sizing: border-box; font-weight:bold; color:#a94442;border-color:#dca7a7; background-image:linear-gradient(to bottom, #f2dede 0%, #e7c3c3 100%);padding:15px; border-radius:4px; z-index:9999";
        alertDiv.innerText = "No internet connection is available.";
        document.getElementsByTagName('body')[0].appendChild(alertDiv);
    }
    else if (alertDiv && navigator.onLine) {
        document.getElementsByTagName('body')[0].removeChild(alertDiv);
        alertDiv = null;
    }
}

window.addEventListener('load', updateOnlineStatus);
window.addEventListener('online', updateOnlineStatus);
window.addEventListener('offline', updateOnlineStatus);
window.addEventListener('beforeunload', (e) => {
    if (!navigator.onLine) {
        window.stop();
        e.preventDefault();
        console.log('no connection and if you reload you will loose contents');
    }
});

axios.interceptors.request.use((config) => {
    var connection = navigator.onLine;
    if (!connection) { return Promise.reject('Request can not be processed because no internet connection is available') };
    var rawUser = localStorage.getItem('user');
    if (rawUser) {
        var user = JSON.parse(localStorage.getItem('user'));
        config.headers.Authorization = `Bearer ${user.authToken}`;
    }
    //config.url += "/";
    return config;
}, (error) => {
    console.log(error);
    return Promise.reject(error);
});

axios.interceptors.response.use((response) => {
    if (response.status === 401)
        logOut();
    return response;
}, (error) => {
    if (error.response && error.response.status === 401)
        logOut();
    if (error.response && error.response.data && error.response.data.ErrorMessage)
        console.log(error.response.data.ErrorMessage);
    try {
        if (error.response && error.response.data && !error.response.data.ErrorMessage && error.response.data.toLowerCase().search('accessdenied') !== -1) {
            logOut();
        }
        else
            console.log(error.message);
    } catch (e) {
    }
    return Promise.reject(error);
});

function logOut() {
    localStorage.clear();
    window.location = "/";
}