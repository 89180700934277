import axios from 'axios';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import SMIGrid from '../SMIGrid/SMIGrid';
import { Grid, GridColumn as Column, GridDetailRow } from '@progress/kendo-react-grid';
import { Dialog } from '@progress/kendo-react-dialogs';
import SMIActionColumn from '../SMIGrid/SMIActionColumn';
import SendMessage from '../SendMessage/SendMessage';
import MSGRecipientType from '../SendMessage/MSGRecipientType';
import { allVehicles } from './MessageInbox';

export default class DetailComponent extends GridDetailRow {
        
    state = {
        showSendMessage: false,
        replyTo: null,
        preselectedRecipients: []
    }

    componentDidMount() {     
        let data = this.props.dataItem.children;
        if (data && data.length > 0) {
            let unreadMessage = [];

            for (let i = 0; i < data.length; i++) {
                if (data[i].isUnread) {
                    data[i].isUnread = false;
                    if (unreadMessage.indexOf(data[i].id) === -1)
                        unreadMessage.push(data[i].id)
                }
            }

            if (unreadMessage && unreadMessage.length > 0) {
                this.markRead(unreadMessage);
            }
        }
    }
   
    replyCell = props => {
        return (<td><button className="k-grid-reply" onClick={e=> this.replyHandler(e, props)}></button></td>);
    }

    replyHandler = (e, props) => {        
        let dataItem = props.dataItem;
        let vehicleName = "";
        if (allVehicles) {
            let vehicle = allVehicles.find(v => v.id === dataItem.vehicleId);
            if (vehicle)
                vehicleName = vehicle.name;
        }
        let vehicleIds = [{ id: dataItem.vehicleId, type: MSGRecipientType.Vehicle, text: vehicleName }];
        this.setState({ showSendMessage: true, replyTo: dataItem.id, preselectedRecipients: vehicleIds });        
    }

    onClose = e => {
        this.setState({ showSendMessage: false });
    }

    dataRender = (trElement, props) => {

        const isNoChildren = !props.dataItem.children || props.dataItem.children.length === 0;
        let className = " ";
        if (isNoChildren) className += " no-replies";
        if (props.dataItem.markUnread) className += " is-unread";
        const trProps = { className: className };

        return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    markRead = (ids) => {
        if (ids && ids.length > 0) {
            axios.delete('/api/MessageInbox', { data: JSON.stringify(ids) }).then(response => {
                console.log('Message is marked read');
            }).catch(err => console.log('error deleting data'));
        }
    }
    render() {
        const detailsData = this.props.dataItem.children;
        
        if (detailsData && detailsData.length > 0) {
            const defaultHeight = detailsData.length < 2? 120 : 70;
            const gridHeight = (detailsData.length * defaultHeight) + 'px';
            return (
                <Fragment>
                    <SMIGrid data={detailsData} editable={false} filterable={false} rowRender={this.dataRender} 
                        style={{ height: gridHeight, overflow: 'auto', maxHeight: '500px', marginBottom: '10px' }}>
                    <Column field="recipient" title="Recipient" />
                    <Column field="sentBy" title="Sent By" />
                    <Column field="time" title="Time" />
                    <Column field="message" title="Message" />
                    <Column field="yesNo" title="Yes/No" />
                    <Column className="k-grid-login" title="Reply" filterable={false} width={75} cell={this.replyCell} />
                    </SMIGrid>
                    {this.state.showSendMessage && <div className="send-message">
                        <Dialog title="Send Message" width={1120} height={600} style={{ zIndex: 200 }} onClose={this.onClose}>
                            <SendMessage preselectedRecipients={this.state.preselectedRecipients} replyTo={this.state.replyTo} onClose={this.onClose} />
                        </Dialog>
                    </div>
                    }
                </Fragment>
            );
        }
        return (
            <div style={{ height: "50px", width: '100%' }}>
                <div style={{ position: 'absolute', width: '100%' }}>
                    <div className="k-loading-image" />
                </div>
            </div>
        );
    }
}