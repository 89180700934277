import React, { Component } from 'react';
import axios from 'axios';
import EmergencyPresentation from './EmergencyPresentation';
import * as signalR from '@aspnet/signalr';

export default class Emergencies extends Component {

    state = {
        emergencyMessages: [],
        acknowledgmentsInProgress: [],
        acknowledged: [],
        isLoading: false
    }

    emergencyPollingInterval = null;
    signalRConnection = null;

    componentDidMount() {
        axios.get("/api/HasOperation?operation=ViewEmergencies").then(response => {
            if (response.data) {
                this.emergencyPollingInterval = setInterval(this.loadEmergencyMessages, 5000);
                this.loadEmergencyMessages();
                this.openSignalR();
            };
        });
        
    }

    openSignalR() {
        //Use SignalR to listen for emergencies acknowledged by other users and mark them so that they will no longer be shown
        this.signalRConnection = new signalR.HubConnectionBuilder()
            .withUrl("/ResolvedEmergenciesHub")
            .build();        
        this.signalRConnection.keepAliveIntervalInMilliseconds = 1000 * 60 * 3; // Three minutes
        this.signalRConnection.serverTimeoutInMilliseconds = 1000 * 60 * 6; // Six minutes

        this.signalRConnection.on("EmergencyResolved", (vehicleAlertLogId) => {
            if (this.state.acknowledged.indexOf(vehicleAlertLogId) === -1)
                this.setState({ acknowledged: this.state.acknowledged.concat(vehicleAlertLogId) });
        });
        this.signalRConnection.start();
    }

    componentWillUnmount() {
        if (this.emergencyPollingInterval)
            clearInterval(this.emergencyPollingInterval);
        if (this.signalRConnection)
            this.signalRConnection.stop();
    }

    loadEmergencyMessages = () => {
        if (!this.state.isLoading) {
            this.setState({ isLoading: true });
            axios.get('/api/Emergency').then(result => {
                if (result.data && result.headers['content-type'].search('application/json') > -1)
                    this.setState({ emergencyMessages: result.data });
            }).catch(e => console.log('Emergency API error'))
                .finally(() => this.setState({ isLoading: false }));
        }
    }

    onSendMessage = (em) => {
        let emergencyMark = '@em@';
        let payloadRecipients = [];

        payloadRecipients.push({
            id: em.vehicle.id,
            type: 'Vehicle',
            isClass: false
        });

        let acknMessage = emergencyMark + 'Emergency:' + em.alertText + ' Incident ID:' + em.incidentId + ' Date:' + em.date + ' Time:' + em.time;

        let payload = {
            priority: 1,
            message: acknMessage,
            replyTo: 0,
            recipients: payloadRecipients
        };

        axios.post('/api/SendMessage', payload).then(response => { }).finally(() => {

        });
    }

    sendAcknowledge = (emergency) => {
        let alertLogId = emergency.vehicleAlertLogId;

        this.setState({
            acknowledgmentsInProgress: this.state.acknowledgmentsInProgress.concat([alertLogId])
        });
        axios.post('/api/Emergency', { vehicleAlertLogId: alertLogId }).then(() => {
            this.setState({ acknowledged: this.state.acknowledged.concat(alertLogId) });
            // Send emergency resolution event to signalR hub so it will be pushed to all users
            if (this.signalRConnection)
                this.signalRConnection.invoke("ResolveEmergency", alertLogId).catch(err => console.error(err.toString()));
        })
            .catch(() => {
                alert('There was an error marking this emergency acknowledged');
            })
            .finally(() => {
                this.setState({ acknowledgmentsInProgress: this.state.acknowledgmentsInProgress.filter(id => id !== alertLogId) });
            })
    }

    render() {
        if (!this.state.emergencyMessages) return null;
        return this.state.emergencyMessages
            .filter(emergency => this.state.acknowledged.indexOf(emergency.vehicleAlertLogId) === -1) //Hide emergencies after acknowledge button is clicked
            .map(emergency => <EmergencyPresentation key={emergency.vehicleAlertLogId} emergency={emergency} onRespond={() => this.props.onSendMessage(emergency.vehicle)} onAcknowledge={() => { this.sendAcknowledge(emergency); this.onSendMessage(emergency); }} isBusy={this.state.acknowledgmentsInProgress.indexOf(emergency.vehicleAlertLogId) !== -1} />)
    }
}